import { EnergyLabel } from 'components/Layout';
import { PriceNew, StrikePriceNew } from 'components/PriceNew/PriceNew';
import { useElementLoaded } from 'hooks/useElementLoaded';
import { useElementViewed } from 'hooks/useElementViewed';
import { useIntl } from 'react-intl';
import {
  ATPMessage,
  Button,
  Checkbox,
  Countdown,
  IconButton,
  Link,
  ProductCardContent,
  ProductCardDescription,
  ProductCardFooter,
  ProductCardImage,
  ProductCardImageWrapper,
  ProductCardSigning,
  ProductCardTitle,
  ProductCardWishlistIconButton,
  ProductCard as ProductCardWrapper,
  ProductPills,
  Rating,
  RatingMessage,
  Text,
} from 'ui';
import { ProductCardCompareProps } from './types';

const ProductCardCompare = ({
  atp,
  attributes,
  buttonLabel,
  className,
  code,
  disabledAddToCartButton,
  endDate,
  energyLabel,
  href,
  image,
  inWishlist = false,
  isCompared,
  onAddToCart,
  onClick,
  onCompare,
  onLoaded,
  onRemove,
  onViewed,
  onWishlistClick,
  pills,
  price,
  product,
  rating,
  signings,
  strikePrice,
  title,
}: ProductCardCompareProps) => {
  const { formatMessage } = useIntl();
  const { ref } = useElementViewed(onViewed);
  useElementLoaded(onLoaded);

  return (
    <ProductCardWrapper ref={ref} className={className}>
      <div className="relative w-full">
        <ProductCardWishlistIconButton inWishlist={inWishlist} onWishlistClick={onWishlistClick} />

        <div className="absolute right-0 top-0 z-base flex flex-col items-end gap-1">
          <IconButton onClick={onRemove} variant="ghost" icon={{ name: 'times', styling: 'far' }} />

          {signings?.map((signing) => <ProductCardSigning key={signing.id} signing={signing} />)}
        </div>
      </div>

      <div className="relative">
        <Link href={href} onClick={onClick}>
          <ProductCardImageWrapper>
            {image?.src && (
              <ProductCardImage useNext fill nextSizes={{ default: 300 }} src={image.src} alt={image.alt} />
            )}
          </ProductCardImageWrapper>
        </Link>
        {energyLabel && (
          <EnergyLabel energyLabel={energyLabel} flipHorizontal className="absolute left-0 top-1/2 -translate-y-1/2" />
        )}
      </div>

      <ProductCardContent>
        <ProductCardTitle onClick={onClick} href={href} className="md:h-10">
          {title}
        </ProductCardTitle>

        <div className="flex items-center gap-1">
          <Rating>{rating.value}</Rating>
          <RatingMessage>
            {rating.quantity}{' '}
            {formatMessage({
              id: rating.quantity === 1 ? 'product_card_singular_rating_message' : 'product_card_plural_rating_message',
            })}
          </RatingMessage>
        </div>

        {!!price && (
          <div className="flex items-center gap-2.5">
            <PriceNew>{price}</PriceNew>
            {!!strikePrice && <StrikePriceNew>{strikePrice}</StrikePriceNew>}
          </div>
        )}

        {!!pills?.length && <ProductPills pills={pills} />}

        {!!atp?.label && (
          <ATPMessage
            variant={atp.variant}
            product={product}
            preOrderLabel={atp?.preOrderLabel}
            canBeSold={atp.canBeSold}
            availableInStores={atp.availableInStores}
          >
            {atp.label}
          </ATPMessage>
        )}

        {endDate && (
          <Countdown
            date={endDate}
            dayLabel={formatMessage({ id: 'product_countdown_days' })}
            hourLabel={formatMessage({ id: 'product_countdown_hours' })}
            minuteLabel={formatMessage({ id: 'product_countdown_minutes' })}
            secondLabel={formatMessage({ id: 'product_countdown_seconds' })}
          />
        )}

        {!!attributes?.length && <ProductCardDescription>{attributes}</ProductCardDescription>}

        {onCompare && (
          <div className="flex items-center gap-2">
            <Checkbox
              checked={isCompared}
              onClick={onCompare}
              value={`${code}-checkbox`}
              size="small"
              id={`${code}-checkbox`}
            />
            <Text tag="label" htmlFor={`${code}-checkbox`} type="small">
              {formatMessage({ id: 'product_compare_checkbox_text' })}
            </Text>
          </div>
        )}
      </ProductCardContent>

      {onAddToCart && (
        <ProductCardFooter>
          <Button
            disabled={disabledAddToCartButton}
            onClick={onAddToCart}
            className="w-full"
            icon={{ name: 'cart-shopping', styling: 'far' }}
          >
            {buttonLabel}
          </Button>
        </ProductCardFooter>
      )}
    </ProductCardWrapper>
  );
};

export { ProductCardCompare };
